<template>
  <div class="survey-landing text-center mt-sm-15 pt-md-15">
    <h1>{{ $t("survey.landingTitle") }}</h1>

    <p class="subtitle mb-15 mt-5">{{ $t("survey.landingSubtitle") }}</p>

    <v-btn color="primary" x-large @click="$emit('next')">
      {{ $t("survey.landingButton") }}
    </v-btn>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: bold;
  font-size: 40px;
}

p {
  font-size: 25px;
}
</style>
